@import './assets/variables.scss';
@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';
@import 'react-phone-input-2/lib/style.css';

* {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: $inter-family !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeSpeed;
}

*,
:before,
:after {
    margin: 0;
    padding: 0;
    border: 0;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    height: 100dvh;
    min-height: 320px;
    min-width: 320px;

    @media screen and (max-width: 2400px) {
        font-size: 12px;
    }

    @media screen and (max-width: 1920px) {
        font-size: 10px;
    }
}

body {
    position: relative;
    padding: 0;
    margin: 0;
    border: 0;
    width: 100%;
    overflow-x: hidden;
    background-color: $white;
    font-family: $inter-family;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.9rem;
    color: $black-1;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    &:has(.portal) {
        overflow: hidden;
    }
}

#root {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

a {
    cursor: pointer;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

select::-ms-expand {
    display: none;
}

input,
button,
textarea,
select {
    outline: none;
}

.container {
    width: 100%;
    max-width: 123.2rem;
    padding: 0 1.6rem;
    margin: 0 auto;
}

.white-substrate {
    width: 100%;
    padding: 1.6rem;
    border-radius: 1.2rem;
    background-color: $white;
    display: flex;
    flex-direction: column;
}

.react-dropdown-select-dropdown-position-bottom.react-dropdown-select-dropdown {
    border: 1px solid $gray-7;
    background: $white;
    border-radius: 0.8rem;
    padding: 0.5rem 0.2rem;
    box-shadow: none;
    z-index: 10;
    margin-top: 0.3rem;
}

.react-dropdown-select-dropdown-position-bottom .react-dropdown-select-item {
    padding: 0.5rem 1.4rem;
    color: $black-1;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
    border-radius: 0.3rem;

    @media screen and (hover) {
        cursor: pointer;
        transition:
            background-color 0.5s,
            color 0.5s;

        &:hover {
            background-color: $blue-1;
            color: $blue;
        }
    }

    &.react-dropdown-select-item-selected {
        background-color: transparent;
        color: $blue;
    }
}

.react-dropdown-select {
    &.dropdown {
        width: 100%;
        min-height: 4.5rem;
        border: 1px solid $blue;
        border-radius: 0.8rem;
        padding: 1.3rem 1.6rem;
        box-shadow: none !important;
        background-color: transparent;

        @media screen and (hover) {
            cursor: pointer;
            transition: background-color 0.5s;

            &:hover {
                background-color: $blue-1;
            }
        }

        &.little {
            min-height: 4.1rem;
            padding: 0.5rem 0.8rem;
        }

        .react-dropdown-select-content {
            column-gap: 1rem;
            row-gap: 0.5rem;
        }

        .react-dropdown-select-dropdown-handle {
            margin: 0;
            display: flex;
            width: 1.6rem;
            height: 1.6rem;
            background: transparent url(./assets/icons/chevron-down.svg) center no-repeat;
            background-size: contain;
            transition: transform 0.5s;

            svg {
                display: none;
            }
        }

        .react-dropdown-select-input,
        .react-dropdown-select-input::placeholder,
        .react-dropdown-select-content span {
            margin: 0;
            color: $blue;
            font-size: 1.4rem;
            line-height: 1.7rem;
            font-weight: 700;
            background-color: transparent;
            padding: 0;
        }

        .react-dropdown-select-dropdown {
            background: $white;
            border-radius: 0.8rem;
            padding: 0.4rem;
            box-shadow: none;
            width: 100%;
            filter: drop-shadow(0 1.2rem 1.2rem $gray-34);
        }

        .react-dropdown-select-item {
            padding: 1.3rem 1.6rem;
            color: $gray-30;
            font-size: 1.4rem;
            line-height: 1.7rem;
            font-weight: 700;
            border-radius: 0.8rem;

            @media screen and (hover) {
                cursor: pointer;
                transition:
                    background-color 0.5s,
                    color 0.5s;

                &:hover {
                    background-color: $blue-1;
                    color: $blue;
                }
            }

            &.react-dropdown-select-item-selected {
                background-color: $blue-1;
                color: $blue;
            }
        }
    }

    &.dropdown_gray {
        &.dropdown {
            border-color: $gray-32;
            background-color: $gray-32;
            min-height: 4.5rem;

            @media screen and (hover) {
                &:hover {
                    background-color: $gray-4;
                }
            }

            .react-dropdown-select-input,
            .react-dropdown-select-input::placeholder,
            .react-dropdown-select-content span {
                color: $black-9;
            }

            .react-dropdown-select-dropdown {
                border-color: $gray-32;
                max-height: 18rem;
            }

            .react-dropdown-select-item {
                @media screen and (hover) {
                    &:hover {
                        background-color: $gray-4;
                        color: $black-9;
                    }
                }
            }

            &.react-dropdown-select-item-selected {
                color: $black-9;
            }
        }

        .react-dropdown-select-dropdown-handle {
            filter: grayscale(1);
        }
    }

    &.dropdown.little {
        min-height: 4.1rem;
        padding: 0.5rem 0.8rem;

        .react-dropdown-select-input,
        .react-dropdown-select-input::placeholder,
        .react-dropdown-select-content span {
            font-size: 1.4rem;
            line-height: 1.7rem;
        }
    }

    &.dropdown_error {
        &.dropdown {
            border-color: $red;
        }
    }
}

.big-calendar {
    background-color: $white;
    color: $gray-3;

    @media screen and (max-width: 590px) {
        padding: 1.6rem 2.4rem;
    }

    @media screen and (max-width: 370px) {
        padding: 1.6rem 1rem;
    }

    .rdrDefinedRangesWrapper {
        padding: 1.2rem 1.6rem;
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;
        background: transparent;
        border-right: 1px solid $gray-8;
    }

    .rdrStaticRanges {
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;
    }

    .rdrStaticRange {
        background: transparent;
        border-bottom: none;
        padding: 1rem 1.6rem;

        @media screen and (hover) {
            cursor: pointer;
            transition: background-color 0.5s;

            &:hover {
                background-color: $gray-4;
            }
        }

        &.rdrStaticRangeSelected {
            background-color: $blue-1;

            .rdrStaticRangeLabel {
                color: $blue;
            }
        }
    }

    .rdrStaticRangeLabel {
        color: $gray-3;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 400;
        padding: 0;
        background: transparent !important;
    }

    .rdrInputRanges {
        padding: 0;
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;
    }

    .rdrInputRange {
        padding: 1rem 1.6rem;

        span {
            color: $gray-3;
            font-size: 1.2rem;
            line-height: 1.6rem;
            font-weight: 400;
        }
    }

    .rdrInputRangeInput {
        border: solid 1px $gray-9;
        margin-right: 1rem;
        color: $gray-10;

        &::placeholder {
            color: $gray-10;
        }
    }

    .rdrCalendarWrapper {
        padding: 2rem 2.4rem;
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
    }

    .rdrDateDisplayWrapper {
        background-color: transparent;
    }

    .rdrDateDisplay {
        margin: 0;
        display: flex;
        column-gap: 1rem;
    }

    .rdrDateDisplayItem {
        border-radius: none;
        background-color: transparent;
        box-shadow: none;
        border: none;
        margin-left: 0;
        display: flex;

        input {
            border-radius: 0.8rem;
            border: solid 1px $gray-9;
            display: flex;
            height: 4rem;
            color: $gray-10;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 400;

            &::placeholder {
                color: $gray-10;
            }
        }
    }

    .rdrMonthAndYearWrapper {
        height: auto;
        padding-top: 0;
        display: flex;
        justify-content: space-between;
        column-gap: 1rem;
    }

    .rdrNextPrevButton {
        margin: 0;
        border-radius: 0.4rem;
        background-color: $blue;
        width: 2.4rem;
        height: 2.4rem;

        @media screen and (hover) {
            cursor: pointer;
            transition: background-color 0.5s;

            &:hover {
                background-color: rgba($color: $blue, $alpha: 0.7);
            }
        }

        i {
            display: flex;
            border-width: 0.4rem 0.4rem 0.4rem 0.6rem;
            border-color: transparent transparent transparent $white;
        }
    }

    .rdrPprevButton i {
        display: flex;
        border-width: 0.4rem 0.6rem 0.4rem 0.4rem;
        border-color: transparent $white transparent transparent;
    }

    .rdrMonthAndYearPickers {
        column-gap: 0.5rem;
    }

    .rdrMonthPicker,
    .rdrYearPicker {
        margin: 0;
    }

    .rdrMonthAndYearPickers select {
        padding: 0.8rem 2.4rem 0.8rem 1rem;
        border-radius: 4px;
        color: $gray-10;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 400;

        @media screen and (hover) {
            cursor: pointer;
            transition: background-color 0.5s;

            &:hover {
                background-color: $gray-4;
            }
        }
    }

    .rdrMonths {
        margin: 0 -2.4rem;
        width: calc(100% + 2.4rem);

        @media screen and (max-width: 890px) {
            margin: 0;
            width: 100%;
        }

        .rdrMonth {
            padding: 0 2.4rem;

            @media screen and (max-width: 890px) {
                padding: 0;
            }

            &:first-child {
                border-right: 1px solid $gray-9;

                @media screen and (max-width: 890px) {
                    border-right: none;
                }
            }
        }
    }

    .rdrMonthName {
        padding: 0 0 2.4rem;
        color: $gray-3;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 500;
    }

    .rdrWeekDay,
    .rdrDayNumber span {
        color: $gray-3;
        font-weight: 500;
    }

    .rdrDayPassive .rdrDayNumber span {
        color: $gray-10;
    }

    .rdrDayToday .rdrDayNumber {
        background-color: $gray-4;
        border-radius: 0.4rem;
    }

    .rdrDayToday:has(.rdrInRange) .rdrDayNumber,
    .rdrDayToday:has(.rdrStartEdge) .rdrDayNumber,
    .rdrDayToday:has(.rdrEndEdge) .rdrDayNumber {
        background-color: transparent;
    }

    .rdrStartEdge {
        border-top-left-radius: 0.4rem;
        border-bottom-left-radius: 0.4rem;
    }

    .rdrDayEndOfMonth .rdrInRange,
    .rdrDayEndOfMonth .rdrStartEdge,
    .rdrDayEndOfWeek .rdrInRange,
    .rdrDayEndOfWeek .rdrStartEdge {
        border-top-right-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
    }

    .rdrDayStartOfMonth .rdrInRange,
    .rdrDayStartOfMonth .rdrEndEdge,
    .rdrDayStartOfWeek .rdrInRange,
    .rdrDayStartOfWeek .rdrEndEdge {
        border-top-left-radius: 0.4rem;
        border-bottom-left-radius: 0.4rem;
        color: $blue !important;
    }

    .rdrEndEdge {
        border-top-right-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
        color: $blue !important;
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
        color: $white;
    }

    .rdrInRange,
    .rdrStartEdge {
        color: $blue !important;
    }

    .rdrDayToday .rdrDayNumber span:after,
    .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
    .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
    .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
    .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
        display: none;
    }
}

.slick-slider {
    padding-bottom: 2.4rem;

    ul .slick-active {
        button {
            background: $blue;
            width: 0.8rem;
            height: 0.8rem;
            background-clip: content-box;
        }
    }

    .slick-slide>div {
        margin: 0 0.6rem;
    }

    .slick-list {
        margin: 0 -0.6rem;
        width: calc(100% + 1.2rem);
    }

    .slick-arrow {
        width: 2.4rem;
        height: 2.4rem;
        background: transparent url(./assets/icons/slick_chevron.svg) center no-repeat;
        background-size: contain;
        border-radius: 0.4rem;
        position: absolute;
        bottom: 0.6rem;
        top: auto;
        left: auto;
        right: auto;
        transform: none;

        @media screen and (hover) {
            cursor: pointer;
            transition: background-color 0.5s;

            &:hover {
                background-color: $gray-4;
            }
        }

        &::before {
            display: none;
        }

        &.slick-next {
            right: 1.2rem;
        }

        &.slick-prev {
            left: 1.2rem;
            transform: rotateZ(180deg);
        }
    }
}

.code-input {
    width: 100%;
    display: flex;
    column-gap: 0.5rem;
    justify-content: space-between;

    .code-input__input {
        margin-right: 0;
        height: 5.2rem;
        width: 5.2rem;
        max-width: calc((100% - 2.5rem) / 6);
        border: 1px solid $gray-7;
        border-radius: 0.8rem;
        color: $gray-11;
        font-size: 1.8rem;
        line-height: 2rem;
        font-weight: 400;

        &:valid {
            background-color: transparent;
            box-shadow: none;
            border-color: $gray-7;
        }

        &:focus {
            background-color: $gray-4;
            box-shadow: $blue 0 0 0 1px;
        }
    }
}

.infinite-scroll-component__outerdiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 0;
    flex-grow: 1;
}

body:has(.ymaps-2-1-79-map:nth-child(2)) div > .ymaps-2-1-79-map:nth-child(1) {
    display: none;
}
