@import "./../../assets/variables.scss";

.menuLink {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
    justify-content: space-between;
    padding: 1.4rem 1.6rem;
    border-radius: 1.2rem;
    background-color: transparent;

    @media screen and (max-width: 1200px) {
        padding: 0.5rem 0.8rem;
    }

    @media screen and (hover) {
        cursor: pointer;
        transition: background-color 0.5s;

        .menuLink__title {
            transition: color 0.5s, font-weight 0.3s;
        }

        .menuLink__ico {
            transition: stroke 0.5s, fill 0.5s;
        }

        &:hover {
            background-color: $blue-1;

            .menuLink__title {
                color: $blue;
            }
    
            .menuLink__ico {
                fill: $blue;

                &_stroke {
                    stroke: $blue;
                }
            }
        }
    }

    &__wrapper {
        flex-grow: 1;
        display: flex;
        align-items: center;
        column-gap: 0.4rem;
    }

    &__title {
        color: $gray-30;
        font-size: 1.4rem;
        line-height: 1.7rem;
        font-weight: 600;

        @media screen and (max-width: 850px) {
            white-space: nowrap;
        }
    }

    &__ico {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        fill: $gray-30;
        flex-shrink: 0;

        @media screen and (max-width: 1200px) {
            width: 2rem;
            height: 2rem;
        }

        &_stroke {
            stroke: $gray-30;
            fill: none;
        }
    }

    &__count {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        padding: 0.2 0.8rem;
        border-radius: 10rem;
        background-color: $blue;
        color: $white;
        font-size: 1.2rem;
        line-height: 1.4rem;
        font-weight: 700;
    }

    &_active {
        background-color: transparent;
        pointer-events: none;
        position: relative;

        .menuLink__dot {
            @media screen and (max-width: 850px) {
                display: block;
                width: 0.5rem;
                height: 0.5rem;
                background-color: $blue;
                position: absolute;
                right: 0;
                top: calc(50% - 0.25rem);
                border-radius: 50%;
            }
        }

        .menuLink__title {
            color: $blue;
        }

        .menuLink__ico {
            fill: $blue;

            &_stroke {
                stroke: $blue;
            }
        }
    }
}